import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  rivalName: "dendera",
  casinoId: 41,
  GA_TRACKING:'G-M0MVCWM8GP',

  rivalChatGroupName: "Dendera",
  prettyName: "Dendera",
  contactEmail: "support@denderacasino.com",
  docsEmail: "documents@denderacasino.email",

  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/brands/dendera/promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/brands/dendera/promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/brands/dendera/promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/brands/dendera/promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/brands/dendera/promo.png"}
  ],

  //SEO
  metaDescription: "Online casino gaming and gambling at Dendera Casino. Play slots, bet with live dealers, hit the blackjack, and spin roulette wheels. Generous welcome bonuses and deposit bonuses, reward program, secure payment. Captivating gameplay and real money prizes fast!",
  logoName: "dendera-casino-online-casino-logo.png",
  logoAltText: "Online casino Dendera logo with blue and gold circular emblem trusted for slots table games and exclusive gambling bonuses."


};


